import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "selectedPayouts", "selectedAmount", "actionbar" ]

  static values = {
    threshold: Number,
  }

  deselectAll() {
    this.actionbarTarget.classList.add('hidden');
    this.dispatch("deselect-all")
  }

  changeActionbar({ detail: { targets } }) {
    let selectedTargets = targets.filter((el) => el.firstElementChild.checked)
    let selectedAmount = selectedTargets.reduce((sum, target) => { return sum + parseFloat(target.firstElementChild.dataset.billAmount) }, 0);

    this.selectedPayoutsTarget.textContent = selectedTargets.length;
    this.recalculateAmount(selectedAmount)

    if (selectedAmount < this.thresholdValue) {
      document.querySelector('.min-payout-banner').classList.remove('hidden');
      this.actionbarTarget.classList.add('hidden');
    } else {
      document.querySelector('.min-payout-banner').classList.add('hidden');
      this.actionbarTarget.classList.remove('hidden');
    }
  }

  recalculateAmount(selectedAmount) {
    let numberFormat = this.selectedAmountTarget.dataset.numberFormat || 'en-EN'
    let currency = this.selectedAmountTarget.dataset.currency || 'USD'
    let formator = new Intl.NumberFormat(numberFormat, { style: 'currency', currency: currency })
    let currencySymbol = formator.formatToParts(0).find(part => { return part.type == 'currency' }).value
    let formattedValue = formator.format(selectedAmount).replace(currencySymbol, `${currencySymbol} `)
    this.selectedAmountTarget.textContent = formattedValue
  }
}

