import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "show" ]

  connect() {
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    if (isSafari) {
      this.showTarget.classList.toggle('hidden')
    }
  }
}

