import { Controller } from "stimulus"
export default class extends Controller {
  connect(){
    const controller = this
    window.addEventListener("dropzone:success", function(evt) {
      if (evt.detail.file == undefined) return
      controller.setEmailLogo(evt.detail.file)
    });
  }

  setEmailLogo(fileURL){
    const imageContainer = document.getElementById("email-logo")
    imageContainer.innerHTML=`<img src="${fileURL}" class="space-logo" alt="Cirro.io"/>`
  }

  setBackgroundColor() {
    const backgroundElement = document.getElementById("email-background")
    const input = document.getElementById("background-color")
    backgroundElement.style.backgroundColor = input.value
  }
}
