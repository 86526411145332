import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal"]

  close(event) {
    this.modalTarget.removeAttribute("open")
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.close()
    }
  }
}
