import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "sidebar" ]

  toggle(event) {
    event.preventDefault()
    this.sidebarTarget.classList.toggle('is-opened');
  }
}

