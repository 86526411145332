import Rails from '@rails/ujs'
Rails.start()

require("@rails/activestorage").start()

// Tailwind CSS
//import "./stylesheets/application.scss"
// import "cirro-designsystem/src/_assets/images/Cirro-App-Defaultimage.svg"
//
// TODO stop using the js initializer from designsystem
// It's better to configure flatpickr, tom-select and all the other libs with our own stimulus controllers
// import "cirro-designsystem/src/_assets/scripts/app.js"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "./channels"
import "./controllers"
import "./src/timezone"
import "./src/cirro_ready"
import './src/actiontext'

import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

// Custom turbo stream action
import { StreamActions } from "@hotwired/turbo"

StreamActions.browserReplaceState = function() {
	const url = this.getAttribute("url");
  history.replaceState({}, "", url);
}

import './segment_analytics.js'

// Delibrately adding a comment here to trigger asset precompile
