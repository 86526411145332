import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "right", "down", "items" ]

  toggle(event) {
    event.preventDefault()
    this.itemsTarget.classList.toggle('hidden');
    this.rightTarget.classList.toggle('hidden');
    this.downTarget.classList.toggle('hidden');
  }
}

