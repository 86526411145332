import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formInput", "submitButton"]

  connect(){
    this.updateSubmitButtonState()
  }

  updateSubmitButtonState(){
    const allFilled = this.formInputTargets.every(input => input.value.trim() !== "");
    if (allFilled) {
      this.submitButtonTarget.disabled = false
    }else{
      this.submitButtonTarget.disabled = true
    }
  }
}
