import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field", "counter"]

  connect() {
    this.countCharacters()
  }

  countCharacters(event) {
    let characters = this.fieldTarget.value.replace(/(\r)?\n/g, '\r\n').length
    let maxlength = this.counterTarget.dataset.maxlength

    this.counterTarget.innerText = `${characters} of ${maxlength} characters used`

    if (characters > maxlength) {
      this.counterTarget.classList.add("text-red-500")
    } else {
      this.counterTarget.classList.remove("text-red-500")
    }
  }
}
