import { Controller } from "stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = [ "toggleable", "toggleableWithClickOutside" ]

  connect() {
    this.toggleClass = this.data.get("class") || "hidden"
    useClickOutside(this)
  }

  toggle(event) {
    event.preventDefault()

    this.toggleableTargets.forEach( target => {
      target.classList.toggle(this.toggleClass)
    })
    this.toggleableWithClickOutsideTargets.forEach( target => {
      target.classList.toggle(this.toggleClass)
    })
  }

  toggleWithDefaultEvent(event) {
    this.toggleableTargets.forEach( target => {
      target.classList.toggle(this.toggleClass)
    })
    this.toggleableWithClickOutsideTargets.forEach( target => {
      target.classList.toggle(this.toggleClass)
    })
  }

  clickOutside(event) {
    this.toggleableWithClickOutsideTargets.forEach( target => {
      target.classList.add(this.toggleClass)
    })
  }
}
