import Trix from "trix"
import "@rails/actiontext"

Trix.config.textAttributes.italic = {
  tagName: "i",
  inheritable: true
}

class InitialConfiguration {
  constructor(element) {
    this.element = element

    this.setContentClass();
  }

  setContentClass(){
    // By default trix add trix-content class, 
    // but if for rich_text_aria specified additional class, this class will override default one
    this.element.classList.add(...["trix-content"])
  }
}

document.addEventListener("trix-initialize", function(event) {
  new InitialConfiguration(event.target)
})
