import { Controller } from "stimulus"
import { segment_analytics } from "../segment_analytics.js";

export default class extends Controller {
    static values = {
        payload: Object
    }

    track({ params: { payload } }) {
        segment_analytics.track(payload.event, payload.properties);
    }

    resetIdentity() {
        segment_analytics.reset();
    }

    identify({ params: { userId } }) {
        if (userId == this.getStoredUserId()) return;

        segment_analytics.identify(userId);
    }

    page(){
        segment_analytics.page()
    }

    // segment stores ajs_user_id in localstorage
    // which contains " e.g. '"10"'
    getStoredUserId(){
        if (localStorage.ajs_user_id == null) {
            return null;
        } else {
            return parseInt(localStorage.ajs_user_id.replaceAll('"', ''));
        }
    }
}
