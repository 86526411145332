import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "selectAll" ]

  toggleSelectAll() {
    this.checkboxTargets.forEach( target => {
     let checkbox = target.firstElementChild
     checkbox.checked = this.selectAllTarget.firstElementChild.checked
    })

    this.dispatchChangeEvent()
  }

  deselectAll() {
    this.selectAllTarget.firstElementChild.checked = false
    this.toggleSelectAll()
  }

  changeSelection() {
    let selectedSize = this.checkboxTargets.filter((el) => el.firstElementChild.checked).length;

    if (selectedSize < this.checkboxTargets.length) {
      this.selectAllTarget.firstElementChild.checked = false
    } else {
      this.selectAllTarget.firstElementChild.checked = true
    }
      
    this.dispatchChangeEvent()
  }
  
  dispatchChangeEvent() {
    this.dispatch("selection-changed", { detail: { targets: this.checkboxTargets } })
  }
}

