import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "option", "selectedOption" ]

  select(event) {
    let currentTarget = event.currentTarget

    if (currentTarget.classList.contains('selected')) {
      return false
    }

    this.optionTargets.forEach( target => {
      target.classList.remove('selected')
    })

    currentTarget.classList.add('selected')

    this.inputTarget.value = currentTarget.getAttribute('data-value')
    this.selectedOptionTarget.innerHTML = currentTarget.innerHTML
  }
}
