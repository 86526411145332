import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["submitButton", "repoLink", "tooltip"]

  select(event){
    let radioCard = event.target.parentElement;
    let redirectUrl = radioCard.getAttribute('data-url');

    this.submitButtonTarget.setAttribute("href", redirectUrl);
    this.submitButtonTarget.removeAttribute("disabled");
  }

  copyToClipboard(){
    let repoLink = this.repoLinkTarget.getAttribute("href");
    navigator.clipboard.writeText(repoLink);
    this.showTooltip()
  }

  showTooltip(){
    this.tooltipTarget.classList.remove('invisible', 'opacity-0');
    this.tooltipTarget.classList.add('opacity-100');

    setTimeout(() => {
      // Start the fade-out
      this.tooltipTarget.classList.remove('opacity-100');
      this.tooltipTarget.classList.add('opacity-0');

      // Wait for the transition to finish, then hide the tooltip
      setTimeout(() => {
        this.tooltipTarget.classList.add('invisible');
      }, 1000); // This should match the duration of the fade-out transition
    }, 1000); // Tooltip display duration before fade-out starts
  }
}
