// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { application } from "./application"

// Register each controller with Stimulus
import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})

import { Tabs } from "tailwindcss-stimulus-components"
application.register('tabs', Tabs)

import TextareaAutogrow from "stimulus-textarea-autogrow"
application.register('textarea-autogrow', TextareaAutogrow)
