import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "accordion" ]

  toggle(event) {
    event.preventDefault()
    document.querySelectorAll('.gig-invitation-accordion.expand').forEach(item => {
      if (item !== this.accordionTarget)
        item.classList.remove('expand')
    })

    this.accordionTarget.classList.toggle('expand');
  }
}

