import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["input", "hintsNumberOfCharacters", "hintsNumbers", "hintsLetters", "hintsSpecialCharacters"]
  iconClasses = { "text": "icon-eye", "password": "icon-eye-strikethrough" }
  inputTypes = ["password", "text"]
  static values = {
    hideHints: Boolean
  }

  connect() {
    this.checkPassword();
    let formErrors = this.context.element.getElementsByClassName("form-hint error")
    if(this.hideHintsValue == true && formErrors.length > 0){
      this.context.element.getElementsByClassName("form-hint error")[0].style.display = 'none';
    }
  }

  unhide(event) {
    event.target.classList.remove(this.iconClasses[this.inputTarget.type])
    this.changeInputType(this.inputTarget)
    event.target.classList.add(this.iconClasses[this.inputTarget.type])
  }

  changeInputType(target){
    target.type = this.inputTypes.find((el)=>(el != target.type))
  }

  checkPassword() {
    this.checkNumberOfCharacters(this.inputTarget.value);
    this.checkNumbers(this.inputTarget.value);
    this.checkLetters(this.inputTarget.value);
    this.checkSpecialCharacters(this.inputTarget.value);
  }

  checkNumberOfCharacters(input) {
    if(input.length >= 8){
      this.showSuccessHint(this.hintsNumberOfCharactersTarget)
    } else {
      this.showAttentionHint(this.hintsNumberOfCharactersTarget)
    }
  }

  checkNumbers(input) {
    if(input.match(/.*[0-9].*[0-9].*/ )){
      this.showSuccessHint(this.hintsNumbersTarget)
    } else {
      this.showAttentionHint(this.hintsNumbersTarget)
    }
  }

  checkLetters(input) {
    if(input.match(/.*[a-zA-Z].*[a-zA-Z].*/ )){
      this.showSuccessHint(this.hintsLettersTarget)
    } else {
      this.showAttentionHint(this.hintsLettersTarget)
    }
  }

  checkSpecialCharacters(input) {
    if(input.match(/.*[!@#$%^&*()_+\-={};:,.<>?].*[!@#$%^&*()_+\-={};:,.<>?].*/)){
      this.showSuccessHint(this.hintsSpecialCharactersTarget)
    } else {
      this.showAttentionHint(this.hintsSpecialCharactersTarget)
    }
  }

  showSuccessHint(target) {
    target.classList.add("success")
  }

  showAttentionHint(target) {
    target.classList.remove("success")
  }
}
