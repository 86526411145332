import { Controller } from "stimulus"
import TomSelect from 'tom-select'

export default class extends Controller {
  connect() {
    // to avoid flackering we have to add "form-control" on the select element
    // however this needs to be removed before we can apply tom-select style here
    this.element.classList.remove("form-control");

    let enableInput = this.element.getAttribute("data-enable-input") === "true" ? true : false;
    let enableCreateNew = this.element.getAttribute("data-enable-create-new") === "true" ? true : false;;
    let placeholder = this.element.getAttribute("data-placeholder");
    let hannushka = this.element.getAttribute("data-hannushka");
    
    let settings = { closeAfterSelect: true, 
      maxOptions: null, 
      create: enableCreateNew, 
      createOnBlur: enableCreateNew
    };

    if (!enableInput) {
      settings["controlInput"] = null;
      settings["plugins"] = ['no_backspace_delete'];
    }
    if (placeholder) settings["placeholder"] = placeholder;

    if (hannushka) {
      settings["plugins"] = {
        remove_button:{
          label: "",
          title:'Remove this item',
        },
        no_backspace_delete: {}
      };
    }

    this.select = new TomSelect(this.element, settings)
  }

  disconnect() {
    this.select.destroy()
  }
}
