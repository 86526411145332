import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "radioButtonLabel", "fieldsBlock" ]

  check(event) {
    let payoutMethodType = event.currentTarget.getAttribute('data-type')
    
    this.radioButtonLabelTargets.forEach( target => {
      if (target.getAttribute('data-type') === payoutMethodType) {
        target.classList.add('hidden')
      } else {
        target.classList.remove('hidden')
      }
    })

    this.fieldsBlockTargets.forEach( target => {
      if (target.getAttribute('data-type') === payoutMethodType) {
        target.classList.remove('hidden')
      } else {
        target.classList.add('hidden')
      }
    })
  }
}
