import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    window.addEventListener("message", e => {
      var widgetEvent = e.data;

      if (widgetEvent.event === "document.height") {
        var iframeElement = document.getElementById("trolley_iframe");

        if (iframeElement) {
          iframeElement.height = widgetEvent.document.height;
        }
      }
    })
  }

  disconnect() {
    window.removeEventListener("message")
  }
}
