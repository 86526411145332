import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form", "input", "field", "countdown", "wait", "resendLink" ]
  static values = { size: Number, codeArray: Array, focusedInputIndex: Number }

  connect() {
    this._focus();
    this.wait = 5;
    this._count_down();
    this.countdown = setInterval(this._count_down.bind(this), 1000);
  }

  keyPress(event){
    let keyCode = event.keyCode;
    switch (true) {
      case /^\d$/.test(event.key):
        event.preventDefault(); // Prevent default numbers symbols behavior
        // we have a number
        this._setCurrentValue(event.key)
        this._focus(this.focusedInputIndexValue + 1);
        if (this.codeArrayValue.filter(Boolean).length == this.sizeValue) {
          this.formTarget.requestSubmit();
        }
        break;
      case /^[^\d\s\w]$/.test(event.key):
        event.preventDefault(); // Prevent default punctuation symbols behavior
        break;
      case keyCode == 8:
        // backspace
        this._setCurrentValue(null)
        this._focus(this.focusedInputIndexValue - 1);
        break;
      case keyCode == 37:
        // Focus left one input
        this._focus(this.focusedInputIndexValue - 1);
        break;
      case keyCode == 39:
        // Focus right one input
        this._focus(this.focusedInputIndexValue + 1);
        break;
      case keyCode == 38 || keyCode == 40:
        // prevent incrementing or decrementing input values
        // by pressing arrowUp and arrowDown
        event.preventDefault();
        break;
      }
  }

  inputInFocus(){
    this.focusedInputIndexValue = this.fieldTargets.indexOf(document.activeElement)
  }

  paste(event) {
    event.preventDefault()

    let data = event.clipboardData.getData("Text")
    if (data.length >= this.sizeValue && /^\d+$/.test(data)) {
      if(document.activeElement.tagName !== "INPUT"){
        this._focus()
      }
      // pasted a string with 4 characters, all of them digits
      let copiedCodeArray = data.split('').slice(0,4)
      copiedCodeArray.forEach(number => {
        this._setCurrentValue(number)
        this._focus(this.focusedInputIndexValue + 1)
      })
      this.formTarget.requestSubmit()
    }
  }

  submit(event) {
    this.inputTarget.value = this.codeArrayValue.join('')
    for (var field of this.fieldTargets) {
      field.disabled = true
    }
    this.resendLinkTarget.classList.add("hidden")
  }

  resend(event) {
    this.resendLinkTarget.classList.add("link-disabled")
  }

  resent(event) {
    this.resendLinkTarget.classList.add("hidden")
    setTimeout(() => {
      this.resendLinkTarget.classList.remove("link-disabled")
      this.countdownTarget.classList.remove("hidden")
      this.wait = 15
      this._count_down()
    }, 2000)
  }

  _setCurrentValue(value) {
    let memo = [...this.codeArrayValue]
    memo[this.focusedInputIndexValue] = value
    this.codeArrayValue = memo

    this.fieldTargets[this.focusedInputIndexValue].value = this.codeArrayValue[this.focusedInputIndexValue]
  }

  _focus(index = 0){
    // debugger
    if(index < 0)
      index = 0
    else if (index > this.sizeValue - 1 )
      index = this.sizeValue - 1

    this.fieldTargets[index].focus()
  }

  _count_down() {
    this.waitTarget.innerHTML = this.wait
    this.wait -= 1
    if (this.wait == 0) {
      this.countdownTarget.classList.add("hidden")
      this.resendLinkTarget.classList.remove("hidden")
    }
  }

  disconnect() {
    clearInterval(this.countdown)
  }
}