import { Controller } from "stimulus"

// TODO: for now this controller is only used on terms and conditions checkbox
// we might to want extend it in the future to control forms with required fields and the submit button state in general
export default class extends Controller {
  static targets = [ "field", "submitButton" ]

  connect() {
    this.updateSubmitButtonState()
  }

  updateSubmitButtonState(_event) {
    if (this.fieldTargets.every((field) => field.checked)) {
      this.submitButtonTarget.disabled = false 
    } else {
      this.submitButtonTarget.disabled = true
    }
  }
}
